@import "~@/erp/styles/variables/variables.scss";













































































































































































































































































































































































































































































.track-content {
  min-height: calc(100% - 120px);

  h4 {
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 0 0;
  }

  .el-input,
  .el-select {
    width: 100%;
  }

  .label-style {
    position: relative;

    em {
      position: absolute;
      top: -8px;
      left: -18px;
      z-index: 2;

      i {
        font-size: 16px;
      }
    }
  }
}

.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}

::v-deep {
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
}
